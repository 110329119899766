<template>
  <b-form-radio-group v-model="option"
                      :stacked="stacked"
                      buttons
                      class="radio-group">
    <b-form-radio v-for="(option, index) in options"
                  :key="index"
                  :class="[`radio-${index + 1}`, 'radio-btn-common']"
                  :disabled="option.disabled || disabled"
                  :style="style_variables[`radio-${index + 1}`]"
                  :value="option.value">
      {{ option.text }}
    </b-form-radio>
  </b-form-radio-group>
</template>

<!--PLEASE REMEMBER THAT GIVEN COLORS HAVE TO BE IN RGB FORMAT-->

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
      options: {
        type: Array,
        default: () => [],
      },
      selected_at_the_beginning: {
        type: String,
        default: '',
      },
      stacked: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        option: this.selected_at_the_beginning,
        style_variables: {},
      };
    },
    watch: {
      option() {
        this.$emit('input', this.option);
      },
    },
    created() {
      this.options.forEach((el, idx) => {
        const color = (el.rgbColor[0] === '#') ? this.hexToRgb(el.rgbColor) : el.rgbColor;
        this.style_variables[`radio-${idx + 1}`] = {
          [`--opt_color_${idx + 1}`]: color,
          [`--opt_color_${idx + 1}_back`]: `${color.slice(0, -1)}, 0.1)`,
          [`--opt_color_${idx + 1}_bord`]: `${color.slice(0, -1)}, 0.5)`,
        };
      });
    },
  };
</script>

<style lang="scss"
       scoped>
  /* number_of_options should be equal to this.options.length but each attempt to pass this */
  /* parameter from script has a type error (variable was not an integer). */
  /* All attempts to make properly working type conversion turned out to be ineffective. */
  $number_of_options: 12;
  $md-width: 1000px;

  .radio-group {
    background-color: transparent;
    margin-bottom: 1em;
    width: 40em;
    @media screen and (max-width: $md-width) {
      width: unset;
    }
  }

  .btn-group-vertical {
    width: 80%;
  }

  .radio-btn-common {
    border: none;
    border-bottom: 0.2em solid;
    border-radius: 5% 30% !important;
    margin-right: 1em;
    font-size: large;
    box-shadow: none;
  }

  .radio-btn-common:hover {
    opacity: 0.7;
  }

  @for $i from 1 through $number_of_options {
    .radio-#{$i} {
      color: var(--opt_color_#{$i});
      background: var(--opt_color_#{$i}_back);
      border-color: var(--opt_color_#{$i}_bord);
    }
  }

  @for $i from 1 through $number_of_options {
    .radio-#{$i}.active {
      color: white !important;
      background-color: var(--opt_color_#{$i}) !important;
      border-color: var(--opt_color_#{$i}) !important;
    }
  }

</style>
