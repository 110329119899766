import variables from '@/assets/css/exports/variables.scss';

const markingsTypes = [
  {
    value: 'machine_time',
    text: 'Machine Time',
    highlight: 'pink',
    rgbColor: variables.colorPinkMagenta,
    cols: 2,
  },
  {
    value: 'trainings',
    text: 'Trainings',
    highlight: 'purple',
    rgbColor: variables.colorPurpleLight,
    cols: 2,
  },
  {
    value: 'blocked',
    text: 'Blocked',
    highlight: 'blue',
    rgbColor: variables.colorBlue,
    cols: 2,
  },
  {
    value: 'taken',
    text: 'Users` reservations',
    highlight: 'gray',
    rgbColor: variables.colorGrayLight,
    cols: 3,
  },
  {
    value: 'user',
    text: 'Your reservations<br/>(Select proposal first)',
    highlight: 'teal',
    rgbColor: variables.colorTeal,
    cols: 3,
  },
];

const weekdaysMapping = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const markingsTypesWithoutUser = markingsTypes.filter((el) => !['user'].includes(el.value));

const userDashboardReservationsCalendarLayout = {
  default: { columns: 1, rows: 2, isExpanded: false },
  tablet: { columns: 2, rows: 2, isExpanded: false },
  laptop: { columns: 3, rows: 2, isExpanded: false },
  desktop_lg: { columns: 3, rows: 3, isExpanded: true },
  desktop: { columns: 2, rows: 3, isExpanded: true },
};

const managerDashboardReservationsCalendarLayout = {
  default: { columns: 1, rows: 2, isExpanded: true },
  tablet: { columns: 2, rows: 2, isExpanded: true },
  laptop: { columns: 3, rows: 2, isExpanded: true },
  desktop_lg: { columns: 3, rows: 2, isExpanded: true },
  desktop: { columns: 2, rows: 3, isExpanded: true },
};

const configCalendarLayout = {
  default: { columns: 2, rows: 1 },
  tablet: { columns: 2, rows: 1 },
  laptop: { columns: 2, rows: 1 },
  desktop_lg: { columns: 3, rows: 2 },
  desktop: { columns: 2, rows: 2 },
};

const getCalendarLayout = (layoutName) => {
  const layouts = {
    userDashboardReservations: userDashboardReservationsCalendarLayout,
    managerDashboardReservations: managerDashboardReservationsCalendarLayout,
    managerDashboardConfig: configCalendarLayout,
  };
  return layouts[layoutName];
};

function getAvailableOrExcludedWeekdays(weekdays, availableWeekdays) {
  const dayToNumber = { Sun: 1, Mon: 2, Tue: 3, Wed: 4, Thu: 5, Fri: 6, Sat: 7 };
  const numberToDay = { 1: 'Sun', 2: 'Mon', 3: 'Tue', 4: 'Wed', 5: 'Thu', 6: 'Fri', 7: 'Sat' };

  const fullDayList = Object.keys(dayToNumber);
  const fullNumberList = Object.keys(numberToDay).map(Number);
  if (availableWeekdays) {
    const convertedDays = weekdays.map((num) => numberToDay[num]);
    return fullDayList.filter((day) => !convertedDays.includes(day));
  }
  const convertedNumbers = weekdays.map((day) => dayToNumber[day]);
  return fullNumberList.filter((num) => !convertedNumbers.includes(num));
}

export {
  getAvailableOrExcludedWeekdays,
  weekdaysMapping,
  markingsTypes,
  markingsTypesWithoutUser,
  userDashboardReservationsCalendarLayout,
  managerDashboardReservationsCalendarLayout,
  configCalendarLayout,
  getCalendarLayout,
};
