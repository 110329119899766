<template>
  <div>
    <extended-radio-group v-model="radio_option"
                          :disabled="false"
                          :options="radio_options"
                          style="margin-bottom: 2em"/>
    <b-table :current-page="current_page"
             :fields="fields"
             :items="visible_rows"
             :per-page="per_page"
             head-variant="light"
             hover
             striped>
      <template v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="visible_rows"
                         :fields="fields"
                         :not_include_key="['actions','ordinalNumber']"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-delete title="Unbook"
                                 @click="remove_row(row)"/>
      </template>
    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="current_page"
                    :data_length="visible_rows.length"
                    :perPage="per_page"
                    @change_page="change_page">
    </b-table-footer>
  </div>
</template>

<script>
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';
  import bTableFooter from '@/components/b_table_footer';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import moment from 'moment';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    name: 'cryoem_dates_exclude_list',
    components: {
      BaseIconButtonDelete,
      proposalFilter,
      bTableFooter,
      extendedRadioGroup,
      BaseColumnOrdinalNumber,
    },
    props: {
      config: {
        type: Object,
        default: () => ({}),
      },
      fields: {
        type: Array,
        default: () => [],
      },
      per_page: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        id: 1,
        loading: false,
        current_page: 1,
        all_rows: [],
        visible_rows: [],
        radio_options: [
          {
            text: 'Show all dates',
            value: 'all_dates',
            buttonVariant: 'outline-info',
          },
          {
            text: 'Show current dates',
            value: 'current_dates',
            buttonVariant: 'outline-info',
          },
        ],
        radio_option: 'current_dates',
      };
    },
    computed: {
      today_date() {
        return moment(new Date()).format('YYYY-MM-DD');
      },
    },
    methods: {
      change_page(val) {
        this.current_page = val;
      },
      remove_row(row) {
        const data = {
          name: row.item.title.replace(' ', '_'),
          dates: [{ start: row.item.start, end: row.item.end }],
        };
        this.axios.put('/config/cryoem_calendar/dates', data)
          .then((response) => {
            if (response.data.msg) {
              this.$emit('config_changed');
              this.$notify({ type: 'success', title: response.data.msg });
            } else {
              this.$notify({ type: 'error', title: response.data.err });
            }
          });
      },
      set_all_rows(data) {
        const conf_list = [];
        Object.entries(data).forEach(([key, val]) => {
          if (key !== 'weekdays') {
            val.forEach(((el) => {
              if ((this.radio_option === 'current_dates' && el.end >= this.today_date)
                || this.radio_option === 'all_dates') {
                conf_list.push({
                  title: key.replace('_', ' '),
                  start: el.start,
                  end: el.end,
                });
              }
            }));
          }
        });

        this.$set(this, 'all_rows', conf_list);
      },
    },
    watch: {
      // this watcher is needed by the proposal-filter
      all_rows() {
        this.$set(this, 'visible_rows', this.all_rows);
        this.$emit('setup_proposal', this.visible_rows);
      },
      config() {
        return this.set_all_rows(this.config);
      },
      radio_option() {
        this.set_all_rows(this.config);
      },
    },
  };
</script>

<style scoped>
  input {
    border: none;
  }
</style>
