import { Calendar } from '@/helpers/classes/calendar';
import moment from 'moment';

export const calendarMixins = {
  methods: {
    async getClassCalendar(environment, removeWeekdaysValue = false) {
      const calendar = new Calendar();
      await calendar.initialize(environment, removeWeekdaysValue);
      return calendar;
    },
    dateToStringFormat(date, format = 'YYYY-MM-DD') {
      return date ? moment(date).format(format) : null;
    },
  },
};
