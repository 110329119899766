<template>
  <div>
    <b-table :current-page="current_page"
             :fields="fields"
             :items="visible_rows"
             :per-page="per_page"
             head-variant="light"
             hover
             striped>
      <template v-slot:top-row="{fields}">
        <proposal-filter :id="parent"
                         v-model="visible_rows"
                         :fields="fields"
                         :not_include_key="['actions','ordinalNumber']"
                         :selected_call="selected_call"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-delete v-b-modal.modal_comment
                                 title="Unbook"
                                 @click="current_row = row"/>
      </template>
    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="current_page"
                    :data_length="all_rows.length"
                    :perPage="per_page"
                    @change_page="change_page"/>
    <div v-if="first_load && !proposals.length">
      <h1 class="text-center"> No data to display </h1>
    </div>
    <modal_comment @ok_action="remove_row($event, current_row)"/>
  </div>
</template>

<script>
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';
  import bTableFooter from '@/components/b_table_footer';
  import modal_comment from '@/components/operational/cryoem/modal_comment';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import proposalFilter from '@/components/proposal_filter';

  export default {
    name: 'cryoem_reservations_list',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonDelete,
      proposalFilter,
      bTableFooter,
      modal_comment,
    },
    data() {
      return {
        removal_comment: '',
        comment_state: null,
        msg_error: 'Error occurred. Please try again in few minutes or contact User Office',
        loading: false,
        current_page: 1,
        visible_rows: [],
        all_rows: [],
        current_row: {},
        first_load: true,
      };
    },
    props: {
      parent: {
        type: Number,
        required: true,
      },
      proposals: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        default: () => [],
      },
      per_page: {
        type: Number,
        default: 20,
      },
      selected_call: {
        type: String,
        default: '',
      },
    },
    methods: {
      change_page(val) {
        this.current_page = val;
      },
      remove_row(comment, row) {
        const document_id = row.item._id.$oid;
        const put_data = { current: row.item.reservations, comment };
        this.axios.put(`/menage/documents/${document_id}/reservations/`, put_data)
          .then((response) => {
            if (response.data.msg) {
              const indexOfRowInAllRows = this.all_rows.findIndex(
                (rowFromAllRows) => rowFromAllRows.reservations.start === row.item.reservations.start,
              );
              this.$delete(this.all_rows, indexOfRowInAllRows);
              this.$notify({ type: 'success', title: response.data.msg });
              this.$emit('reservations-changed');
            } else {
              this.$notify({ type: 'error', title: this.msg_error });
            }
          });
      },
    },
    watch: {
      proposals() {
        this.all_rows = this.proposals;
        this.$emit('setup_proposal', this.all_rows);
        if (this.proposals.length) {
          this.first_load = false;
        }
      },
      all_rows() {
        this.$set(this, 'visible_rows', this.all_rows);
        this.$emit('setup_proposal', this.visible_rows);
      },
    },
  };
</script>

<style scoped>

</style>
