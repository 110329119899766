<template>
  <div>
    <b-modal id="modal_comment"
             ref="modal"
             centered
             ok-title="Remove reservation"
             ok-variant="danger"
             title="Comment and remove reservation"
             @hidden="resetModal"
             @ok="handleOk"
             @show="resetModal">
      <form ref="form"
            @submit.stop.prevent="handleSubmit">
        <b-form-group :state="comment_state"
                      invalid-feedback="Comment is required"
                      label="Comment"
                      label-for="comment_input">
          <b-form-input id="comment_input"
                        v-model="comment"
                        :state="comment_state"
                        required/>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'modal_comment',
    data() {
      return {
        comment: '',
        comment_state: null,
      };
    },
    methods: {
      checkFormValidity() {
        this.comment_state = this.$refs.form.checkValidity();
        return this.comment_state;
      },
      resetModal() {
        this.comment = '';
        this.comment_state = null;
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();
        // Trigger submit handler
        this.handleSubmit();
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return;
        }
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal_comment');
        });
        // Emit to parent
        this.$emit('ok_action', this.comment);
      },
    },
  };
</script>
