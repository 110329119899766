import { render, staticRenderFns } from "./BaseIconButtonDelete.vue?vue&type=template&id=52ec8a98&scoped=true&"
import script from "./BaseIconButtonDelete.vue?vue&type=script&lang=js&"
export * from "./BaseIconButtonDelete.vue?vue&type=script&lang=js&"
import style0 from "./BaseIconButtonDelete.vue?vue&type=style&index=0&id=52ec8a98&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ec8a98",
  null
  
)

export default component.exports