<template>
  <base-date-picker :calendar-class-instance="calendarClassInstance"
                    :clear-selected-value-trigger="clearSelectedValueTrigger"
                    :dates-range="datesRange"
                    :layout="calendarLayout"
                    @day-click="handleShift"
                    @selected-value-update="setSelectedValue"/>
</template>

<script>
  import { getCalendarLayout } from '@/helpers/cryoemCalendars';
  import { calendarMixins } from '@/mixins/calendar';
  import BaseDatePicker from '@/components/calendars/BaseDatePicker';

  export default {
    name: 'CryoemCalendarReservations',
    components: { BaseDatePicker },
    mixins: [calendarMixins],
    model: { prop: 'submitSelected' },
    props: {
      datesRange: {
        type: Object,
        default: () => ({}),
      },
      layoutType: {
        type: String,
        require: true,
      },
      proposalId: {
        type: String,
        default: '',
      },
      proposalLengthOfShiftInDays: {
        type: Number,
        required: true,
      },
      submitSelected: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedValue: null,
        clickedDay: null,
        previousSelectedValue: null,
        clearSelectedValueTrigger: false,
        numberOfAllReservedShifts: 0,
        lengthOfShift: 0,
        numberOfFinalGradeShifts: 0,
        calendarClassInstance: {},
      };
    },
    computed: {
      calendarLayout() {
        return getCalendarLayout(`${this.layoutType}Reservations`);
      },
      clickedDayIndexInProposalReservations() {
        return this.proposalReservations.findIndex((el) => el.start === this.clickedDay);
      },
      indexOfSelectingInAttribute() {
        return this.calendarClassInstance.findAttrIndex('userSelecting');
      },
      isDateDisabled() {
        return !this.isSameDateClickedTwiceInARow && this.isSelectedValueDifferentThanClickedDay;
      },
      isDateInConflict() {
        const isConflictWithAttrs = this.calendarClassInstance.areDatesInConflictWithAttrs(this.selectedDatesObject);
        return isConflictWithAttrs && !this.isDatePreviouslyInProposalReservations;
      },
      isDatePreviouslyInProposalReservations() {
        return this.clickedDayIndexInProposalReservations !== -1;
      },
      isSameDateClickedTwiceInARow() {
        return !this.selectedValue && this.previousSelectedValue === this.clickedDay;
      },
      isSelectedValueDifferentThanClickedDay() {
        return !this.selectedValue || this.selectedValueISODateFormat !== this.clickedDay;
      },
      numberOfFreeShifts() {
        return this.numberOfFinalGradeShifts - this.numberOfAllReservedShifts;
      },
      proposalReservations() {
        return this.calendarClassInstance.attributes[this.indexOfSelectingInAttribute].dates;
      },
      selectedDatesObject() {
        return {
          start: this.selectedValueISODateFormat,
          end: this.dateToStringFormat((this.getEndDayOfShift(this.selectedValue))),
        };
      },
      selectedValueISODateFormat() {
        return this.dateToStringFormat(this.selectedValue);
      },
    },
    watch: {
      numberOfAllReservedShifts: {
        handler() {
          this.$emit('number-of-all-reserved-shifts-update', this.numberOfAllReservedShifts);
        },
      },
      proposalId: {
        handler() {
          this.getDocumentReservations();
        },
      },
      submitSelected: {
        async handler() {
          if (this.submitSelected === true) {
            await this.submitDocumentReservations();
          }
          this.$emit('input', false);
        },
      },
    },
    async created() {
      this.calendarClassInstance = await this.getClassCalendar('cryoem');
    },
    methods: {
      addReservation() {
        this.calendarClassInstance.addNewDateToAttrDates(this.selectedDatesObject, this.indexOfSelectingInAttribute);
        this.numberOfAllReservedShifts += (this.lengthOfShift === 3) ? 1 : 2;
      },
      getEndDayOfShift(date) {
        if (date) {
          const result = new Date(date);
          result.setDate(result.getDate() + this.lengthOfShift - 1);
          return result;
        }
        return date;
      },
      clearSelectedValue() {
        this.clearSelectedValueTrigger = !this.clearSelectedValueTrigger;
      },
      communicateDisabledDate() {
        if (this.isDatePreviouslyInProposalReservations) {
          this.$notify({ type: 'error', title: 'Reserved date' });
        } else {
          this.$notify({ type: 'error', title: 'Disabled date' });
        }
        this.clearSelectedValue();
      },
      async getDocumentReservations() {
        if (this.proposalId) {
          const { data: resp } = await this.axios.get(`/documents/${this.proposalId}/cryoem/reservations`);
          const numberOfAllShifts = Number(resp.granted_shifts);
          const proposalReservations = resp.reserved_shifts;
          const multiplierShiftsPerDay = (this.lengthOfShift === 3) ? 1 : 2;
          const numberOfPreviousReservedShifts = multiplierShiftsPerDay * proposalReservations.length;
          this.setTakenAttrAndReservedShifts(numberOfAllShifts, numberOfPreviousReservedShifts, proposalReservations);
        } else {
          this.setTakenAttrAndReservedShifts(null, 0, []);
        }
      },
      handleShift(event) {
        this.clickedDay = event.id;
        if (this.proposalId) {
          if (this.proposalLengthOfShiftInDays) {
            this.lengthOfShift = this.proposalLengthOfShiftInDays;
          }
          this.handleShiftSelectedProposal();
        } else {
          this.$notify({ type: 'error', title: 'No selected proposal' });
          this.clearSelectedValue();
        }
        this.previousSelectedValue = this.selectedValueISODateFormat;
      },
      handleShiftAvailableDate() {
        if (this.isDateInConflict) {
          this.$notify({ type: 'error', title: 'Dates conflict' });
          this.clearSelectedValue();
        } else {
          this.handleShiftSelectableDate();
        }
      },
      handleShiftReservation() {
        if (this.numberOfFreeShifts > 0) {
          this.addReservation();
        } else {
          this.$notify({ type: 'error', title: 'Too many selected shifts' });
          this.clearSelectedValue();
        }
      },
      handleShiftSelectableDate() {
        if (this.isDatePreviouslyInProposalReservations) {
          this.removeReservation();
        } else {
          this.handleShiftReservation();
        }
      },
      handleShiftSelectedProposal() {
        if (this.isDateDisabled) {
          this.communicateDisabledDate();
        } else {
          this.handleShiftAvailableDate();
        }
      },
      removeReservation() {
        const indexOfDateToRemove = this.clickedDayIndexInProposalReservations;
        this.calendarClassInstance.removeDateFromAttrDates(indexOfDateToRemove, this.indexOfSelectingInAttribute);
        this.numberOfAllReservedShifts -= (this.lengthOfShift === 3) ? 1 : 2;
        this.clearSelectedValue();
      },
      setSelectedValue(data) {
        this.selectedValue = data;
      },
      setTakenAttrAndReservedShifts(numberOfAllShifts, numberOfPreviousReservedShifts, datesOfPreviousReservedShifts) {
        this.numberOfFinalGradeShifts = numberOfAllShifts;
        this.$emit('number-of-all-shifts-update', this.numberOfFinalGradeShifts);
        this.numberOfAllReservedShifts = numberOfPreviousReservedShifts;

        this.calendarClassInstance.setWholeAttrDates(datesOfPreviousReservedShifts, this.indexOfSelectingInAttribute);
        this.clearSelectedValue();
      },
      async submitDocumentReservations() {
        const submitData = { objectId: this.proposalId, reservations: this.proposalReservations };
        await this.$store.dispatch('calendar/submitDocumentReservationsWithNotify', submitData);

        this.previousSelectedValue = null;
        await this.calendarClassInstance.loadReservations('cryoem');
        await this.getDocumentReservations();
      },
    },
  };

</script>

<style scoped>

</style>
