import { store } from '@/store';

export class Calendar {
  constructor() {
    this.removeWeekdaysFromConfig = false;
    this.allConfigs = {};
    this.allReservations = [];
    this.attributes = [];
    this.environment = '';
  }

  async loadConfig() {
    await store.dispatch('calendar/loadCalendarConfig', this.environment);
    this.setStoredConfig();
  }

  async loadReservations() {
    await store.dispatch('calendar/loadCalendarReservations', this.environment);
    this.setReservations();
  }

  setStoredConfig() {
    this.allConfigs = store.state.calendar.calendarConfig[this.environment];

    if (this.removeWeekdaysFromConfig) {
      this.allConfigs = Object.fromEntries(
        Object.entries(this.allConfigs).filter(([key]) => key !== 'weekdays'),
      );
    }
  }

  setReservations() {
    this.allReservations = store.state.calendar.calendarReservations[this.environment];
  }

  findAttrIndex(name) {
    return this.attributes.findIndex((el) => el.key === name);
  }

  addNewObjectToAttrs(keyToAdd, keyColor) {
    this.attributes.push(
      {
        key: keyToAdd,
        highlight: keyColor,
        dates: [],
      },
    );
  }

  clearAttributes() {
    this.attributes = [];
  }

  addNewDateToAttrDates(date, attrIdx) {
    this.attributes[attrIdx].dates.push(date);
  }

  removeDateFromAttrDates(dateIdx, attrIdx) {
    this.attributes[attrIdx].dates.splice(dateIdx, 1);
  }

  setWholeAttrDates(allDates, attrIdx) {
    this.attributes[attrIdx].dates = allDates;
  }

  areDatesInConflictWithAttrs(dates) {
    let conflict = false;
    Object.values(this.attributes).forEach((attr) => {
      const conflictWithAttr = attr.dates.some((attrDatesObj) => (
        (attrDatesObj.start <= dates.start && dates.start <= attrDatesObj.end)
        || (attrDatesObj.start <= dates.end && dates.end <= attrDatesObj.end)
      ) || (dates.start <= attrDatesObj.start && attrDatesObj.end <= dates.end));
      if (conflictWithAttr) {
        conflict = true;
      }
    });
    return conflict;
  }

  async initialize(environment, removeWeekdaysValue) {
    this.removeWeekdaysFromConfig = removeWeekdaysValue;
    this.environment = environment;
    this.setStoredConfig();
    this.setReservations();

    if (Object.keys(this.allConfigs).length === 0) {
      await this.loadConfig();
    }
    if (this.allReservations.length === 0) {
      await this.loadReservations();
    }
  }
}
